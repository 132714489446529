
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.formWrapper {
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  @include mobile {
    padding-top: 38px;
  }

  .errorMessage {
    margin-top: 24px;
    color: #ff4d4f;

    @include mobile {
      margin-top: 13px;
      font-size: 10px;
      line-height: 17px;
      letter-spacing: 1px;
    }
  }

  .successMessage {
    margin-top: 24px;
    color: #5fff4d;

    @include mobile {
      margin-top: 13px;
      font-size: 10px;
      line-height: 17px;
      letter-spacing: 1px;
    }
  }
}
