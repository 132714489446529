
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-bottom: 48px;
  }

  @include mobile {
    width: 270px;

    &:last-of-type {
      margin-bottom: 34px;
    }
  }

  .inputWrapperHeader {
    display: flex;

    .inputLabel {
      color: #557192;
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 1.4px;

      @include mobile {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.7px;
      }
    }

    .errorMessage {
      margin-left: auto;
      color: #ff111f;
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 1.4px;

      @include mobile {
        font-size: 9px;
        line-height: 15px;
        letter-spacing: 0.9px;
      }
    }
  }

  .clipPathBorderWrapper {
    padding: 1px;
    background-color: transparent;
    clip-path: polygon(3% 0, 100% 0, 100% 82%, 97% 100%, 0 100%, 0 18%);

    &.clipPathBorderWrapperError {
      background-color: #ff111f;
    }
  }

  .inputWrapperContent {
    display: flex;
    padding: 8px;
    background-color: rgb(3, 9, 25);
    border: 1px solid transparent;
    clip-path: polygon(3% 0, 100% 0, 100% 82%, 97% 100%, 0 100%, 0 18%);

    @include mobile {
      padding: 5px 8px;
    }

    .tokenMetaBlock {
      display: flex;
      align-items: center;
      padding: 0 8px;

      @include mobile {
        padding: 0;
      }

      .tokenMetaContent {
      }

      .tokenImageWrapper {
        margin-right: 8px;

        @include mobile {
          img {
            width: 26px;
            height: 26px;
          }
        }
      }

      .tokenName {
        color: #119bff;
        font-weight: 400;
        font-size: 22px;
        font-style: normal;
        line-height: 24px;

        @include mobile {
          font-size: 11px;
          line-height: 12px;
        }
      }

      .tokenFullName {
        color: #557192;
        font-weight: 400;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 1.2px;

        @include mobile {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.6px;
        }
      }
    }

    .inputWithBalanceWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-end;

      @include mobile {
        justify-content: space-between;
      }
    }

    .input {
      width: 260px;
      color: #557192;
      font-weight: 400;
      font-size: 22px;
      font-style: normal;
      line-height: 24px;
      text-align: right;
      background-color: transparent;

      &:autofill,
      &:autofill:focus {
        border: 1px solid transparent;
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      @include mobile {
        width: 120px;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .balance {
      color: #557192;
      font-weight: 400;
      font-size: 12px;
      font-style: normal;
      line-height: 24px;
      text-align: right;

      @include mobile {
        font-size: 11px;
        line-height: 12px;
      }
    }
  }
}
