
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .title {
    color: #ffffff;
    font-size: 48px
  }
}
