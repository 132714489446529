
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  height: 492px;
  overflow: hidden;
  background: url("assets/modalBackground.svg") #02111f center / cover;
  transform: translate(-50%, -50%);
  clip-path: polygon(0 0, 94% 0, 100% 9%, 100% 100%, 6% 100%, 0 91%);

  &:focus-visible {
    outline: none;
  }

  @include mobile {
    width: 294px;
    height: 280px;
    background-image: url("assets/mobileModalBackground.svg");
  }
}
