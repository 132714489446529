
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.disconnectButton {
  @include common-button;
  min-width: 168px;
  color: #119bff;
  font-size: 16px;
  letter-spacing: 0.32px;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;
  }

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(20px);
    pointer-events: none;
    stroke: rgba(17, 155, 255, 0);
    stroke-width: 1px;

    @include mobile {
      z-index: 0;
      backdrop-filter: none;
    }
  }

  .content {
    @include mobile {
      position: relative;
      z-index: 2;
      color: #119bff;
      font-weight: 400;
      font-size: 8.667px;
      line-height: 13px;
    }
  }

  @include mobile {
    min-width: 91px;
    height: 39px;
    padding: 10px 0;
  }
}
