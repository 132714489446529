
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.button {
  @include common-button;
  min-width: 290px;

  &:hover {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkxIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMjkxIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAzNi45OTk5TDE3LjAwNTEgNDlINzUuNjZIMTQ1LjVIMjkwVjI1VjEzLjQ3OTFMMjc0LjQ4MSAxSDE0NS41SDFWMjVWMzYuOTk5OVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xMTk3XzY4NikiIHN0cm9rZT0idXJsKCNwYWludDFfbGluZWFyXzExOTdfNjg2KSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTE5N182ODYiIHgxPSIwIiB5MT0iNTAiIHgyPSIzMzYuNTgxIiB5Mj0iLTI2LjIyMTkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agb2Zmc2V0PSIwLjE4MzExMSIgc3RvcC1jb2xvcj0iI0ZGNzJGNyIvPgo8c3RvcCBvZmZzZXQ9IjAuNDMyNTkzIiBzdG9wLWNvbG9yPSIjQzM1NkVGIi8+CjxzdG9wIG9mZnNldD0iMC44NTQ0NDMiIHN0b3AtY29sb3I9IiM1RkJCRTciLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjNkY4RkZGIi8+CjwvbGluZWFyR3JhZGllbnQ+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQxX2xpbmVhcl8xMTk3XzY4NiIgeDE9IjI5Ni4wOTUiIHkxPSItNS4wODI1N2UtMDYiIHgyPSIwLjQwODMwNiIgeTI9Ijc1Ljg2NDEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agb2Zmc2V0PSIwLjQ3Mzk1OCIgc3RvcC1jb2xvcj0iI0ZGQzRGQyIvPgo8c3RvcCBvZmZzZXQ9IjAuOTY4NzUiIHN0b3AtY29sb3I9IiM3MUEzRUYiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K)
      center;

    .background {
      display: none;
    }
  }

  &:disabled {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkxIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMjkxIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAzNi45OTk5TDE3LjAwNTEgNDlINzUuNjZIMTQ1LjVIMjkwVjI1VjEzLjQ3OTFMMjc0LjQ4MSAxSDE0NS41SDFWMjVWMzYuOTk5OVoiIGZpbGw9IiMyOTMzM0YiIHN0cm9rZT0iIzJEM0Y1NSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=)
      center;

    .background {
      display: none;
    }
  }

  @include mobile {
    min-width: 168px;

    &:disabled {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='168' height='38' viewBox='0 0 111 20' fill='none'><path d='M0.512812 14.7602L6.59476 19.3202H28.8836H55.4228H110.333V10.2002V5.82225L104.436 1.08019H55.4228H0.512812V10.2002V14.7602Z' fill='%23557192' stroke='%232D3F55' stroke-width='0.76'/></svg>");
    }

    &:hover {
      background: none;

      .background {
        display: block;
      }
    }
  }

  .background {
    position: absolute;
    top: calc(50% + 4px);
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .content {
    position: relative;
  }
}
