
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.wrapper {
  position: relative;
}

.closePageButton {
  position: absolute;
  top: -48px;
  right: -48px;
  background-color: transparent;
  cursor: pointer;

  &:hover svg > path {
    fill: #ffffff;
  }

  svg > path {
    transition: fill 0.3s;
  }

  @include mobile {
    top: 23px;
    right: 21px;
  }
}
