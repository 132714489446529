
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.button {
  @include common-button;
  min-width: 290px;
  padding-top: 13px;
  padding-bottom: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkxIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMjkxIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAzNi45OTk5TDE3LjAwNTEgNDlINzUuNjZIMTQ1LjVIMjkwVjI1VjEzLjQ3OTFMMjc0LjQ4MSAxSDE0NS41SDFWMjVWMzYuOTk5OVoiIGZpbGw9IiM1NTcxOTIiIHN0cm9rZT0iIzJEM0Y1NSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=)
    center;

  &:hover {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkxIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMjkxIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAzNi45OTk5TDE3LjAwNTEgNDlINzUuNjZIMTQ1LjVIMjkwVjI1VjEzLjQ3OTFMMjc0LjQ4MSAxSDE0NS41SDFWMjVWMzYuOTk5OVoiIGZpbGw9IiNGMTQ5NTMiIHN0cm9rZT0iI0M3NDI0QSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=)
      center;
  }

  @include mobile {
    height: 19px;
    padding: 0;
    min-width: 110px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='111' height='20' viewBox='0 0 111 20' fill='none'><path d='M0.512812 14.7602L6.59476 19.3202H28.8836H55.4228H110.333V10.2002V5.82225L104.436 1.08019H55.4228H0.512812V10.2002V14.7602Z' fill='%23557192' stroke='%232D3F55' stroke-width='0.76'/></svg>");

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='111' height='20' viewBox='0 0 111 20' fill='none'><path d='M0.512812 14.7602L6.59476 19.3202H28.8836H55.4228H110.333V10.2002V5.82225L104.436 1.08019H55.4228H0.512812V10.2002V14.7602Z' fill='%23557192' stroke='%232D3F55' stroke-width='0.76'/></svg>");
    }
  }
}
