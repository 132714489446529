
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.button {
  @include common-button;
  position: absolute;
  top: -48px;
  left: -8px;
  display: flex;
  align-items: center;
  color: #119bff;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;

    svg > path {
      stroke: #ffffff;
    }
  }

  svg > path {
    transition: stroke 0.3s;
  }

  @include mobile {
    top: -40px;
    left: -5px;
  }
}
