
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.textWrapper {
  position: relative;

  .text {
    margin-bottom: 64px;
    color: #119bff;
    font-weight: 500;
    font-size: 30px;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;

    @include mobile {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1.4px;
    }
  }

  .decorativeVectorLeft,
  .decorativeVectorRight {
    position: absolute;
    top: 0;
  }

  .decorativeVectorLeft {
    left: 0;
    transform: translateX(-90%);
  }

  .decorativeVectorRight {
    right: 0;
    transform: translateX(90%);
  }
}

.wingBottom {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 472px;
  height: 472px;
  background: url("assets/wingCenter.png") center;
  transform: translate(-50%, -50%);
}
