
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.wrapper {
  flex: 1;
  width: 100%;
  margin-top: 88px;
  padding: 16px;

  @include mobile {
    margin-top: 55px;
    padding: 0 1px;
  }
}

.tableContentLoader {
  display: flex;
  grid-column: 1 / 6;
  justify-content: center;
}

.tableHeader {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 30%;
  padding: 16px 28px 16px 24px;
  color: #557192;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.28px;
  background: linear-gradient(
    180deg,
    #119bff 0%,
    rgba(17, 155, 255, 0.15) 0.01%,
    rgba(3, 17, 27, 0) 100%
  );
  border-radius: 4px;

  @include mobile {
    grid-template-columns: 35px 45px 45px 45px 1fr;
    padding: 4px 8px;
    font-size: 9px;
    line-height: 14px;
    letter-spacing: 0.18px;
  }

  .tableHeaderLine {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .timeHeaderColumn {
    text-align: right;
  }
}

.tableContent {
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  max-height: 336px;
  padding: 16px 24px 0;
  overflow-y: auto;
  color: #119bff;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.28px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid #119bff;
    border-radius: 2px;
  }

  @include mobile {
    grid-template-columns: 35px 45px 45px 45px 1fr;
    gap: 0;
    max-height: 200px;
    padding: 5px 8px;
    overflow-x: hidden;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }

  .timeItem {
    white-space: nowrap;

    @include mobile {
      text-align: right;
    }
  }
}

.emptyData {
  grid-column: 1 / 6;
  text-align: center;

  @include mobile {
    font-size: 11px;
  }
}
