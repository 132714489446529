@mixin common-button() {
  position: relative;
  padding: 12px 0;
  color: #1d0629;
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 2.2px;
  text-align: center;
  text-shadow: 0 4px 60px rgba(20, 170, 255, 0.5);
  background: transparent;
  border: none;
  cursor: url("../../assets/images/cursor-hover.svg") 8 0, pointer;
  transition: color 0.3s;

  &:active {
    cursor: url("../../assets/images/cursor-pressed.svg") 8 0, pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @include mobile {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
  }
}
