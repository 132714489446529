
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
.connectButton {
  @include common-button;
  min-width: 320px;
  margin-bottom: 0;

  &:hover {
    .decorativeVectorLeft {
      transform: translateX(-64%);
    }

    .decorativeVectorRight {
      transform: translateX(106%);
    }
  }

  .content {
    position: relative;
  }

  @include mobile {
    min-width: 138px;
  }
}

.background {
  position: absolute;
  top: calc(50% + 4px);
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 4px 50px rgba(145, 225, 220, 0.4));
  pointer-events: none;
  stroke: #91cbe6;
  stroke-width: 2px;

  @include mobile {
    min-width: 138px;
  }
}

.decorativeVectorLeft,
.decorativeVectorRight {
  position: absolute;
  transition: transform 0.3s;
}

.decorativeVectorLeft {
  left: 0;
  transform: translateX(-50%);
  transition: transform 0.3s;
}

.decorativeVectorRight {
  top: 12px;
  right: 0;
  transform: translateX(96%);
}
